<template>
    <div>
        <div class="search-bar">
            <v-select
                :items="type"
                v-model="reportType"
                placeholder="Выберите тип отчета"
                height="48"
                hide-details
                outlined
                dense
                item-text="name"
                item-value="id"
            ></v-select>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="filter.dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateRangeText"
                        placeholder="Заезд - выезд"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        height="48"
                        v-on="on"
                        hide-details
                        outlined
                        dense
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="filter.dates"
                    :first-day-of-week="1"
                    no-title
                    range
                    scrollable
                >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"> Отмена</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(filter.dates)">
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
            <v-select
                :items="dateTypes"
                v-model="dateType"
                placeholder="Выберите тип даты"
                height="48"
                hide-details
                outlined
                dense
                item-text="name"
                item-value="id"
            ></v-select>
        </div>
        <div v-if="reportType === 'По услугам'">
            <p>Виды услуг</p>
            <v-radio-group
                v-model="serviceType"
                row
            >
                <v-radio
                    label="Проживание"
                    value="Проживание"
                ></v-radio>
                <v-radio
                    label="Автобусы"
                    value="Автобусы"
                ></v-radio>
                <v-radio
                    label="Туры"
                    value="Туры"
                ></v-radio>
                <v-radio
                    label="Ж/Д"
                    value="Ж/Д"
                ></v-radio>
                <v-radio
                    label="Трансферы"
                    value="Трансферы"
                ></v-radio>
            </v-radio-group>
        </div>
        <div class="mb-8" v-if="reportType === 'По контрагенту'">
            <div class="search-bar">
                <v-select
                    :items="searchTypes"
                    v-model="searchType"
                    placeholder="Выберите тип поиска"
                    height="48"
                    hide-details
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    @change="getAgentItems"
                ></v-select>
            </div>
            <v-autocomplete
                :label="'Поиск'"
                v-model="searchText"
                height="44"
                hide-details
                outlined
                dense
                placeholder="Введите ключевое слово"
                item-text="name"
                item-value="id"
                :items="agent_items"
                :return-object="true"
            >
            </v-autocomplete>
        </div>
        <div class="d-flex justify-end">
            <v-btn color="primary add-btn" height="48" width="200" @click="formulate">
                Сформировать
            </v-btn>
            <v-btn color="primary add-btn" class="ml-3" height="48" width="200" @click="download">
                Скачать
            </v-btn>
        </div>
        <v-text-field 
            v-if="reportType == 'По каналу продаж'"
            v-model="search"
            label="Поиск"
            class="mx-4"
            @change="formulate"
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="list"
            item-key="name"
            class="elevation-1"
            :search="search"
            v-if="tableStatus"
        >   
            <template v-slot:[`item.service_type`]="{ item }">
                {{ item.service_type | convertService }}
            </template>
            <!-- <template v-slot:[`item.created_at`]="{ item }">
                {{ $moment(new Date(item.created_at * 1000)).format('DD.MM.YYYY') }}
            </template> -->
        </v-data-table>
    </div>
</template>

<script>
import {reportService} from "../../services/report.service";
import axios from "axios";

export default {
    data() {
        return {
            type: ['По каналу продаж', 'По услугам', 'По контрагенту'],
            agent_items: [], 
            dateTypes: [
                {
                    name: 'По дате создания', 
                    id: 'by_creation_date'
                }, 
                {
                    name:'По дате брони', 
                    id: 'by_order_date'
                }
            ],
            dateType: '',
            searchTypes: [
                {
                    name: 'По номеру автобуса',
                    id: 'by_bus_number'
                },
                {
                    name: 'По номеру поезда',
                    id: 'by_train_number'
                },
                {
                    name: 'По отелю',
                    id: 'by_hotel'
                },
                {
                    name: 'По туру',
                    id: 'by_tour'
                },
            ],
            searchType: '',
            searchText: '',
            menu: false,
            filter: {
                dates: []
            },
            serviceType: '',
            hotel: '',
            reportType: '',
            search: '',
            list: [],
            tableStatus: false
        }
    },
    watch: {
        serviceType() {
            this.tableStatus = false
        }
    },
    computed: {
        dateRangeText() {
            if (this.filter.dates.length == 2) {
                if (this.filter.dates[0] > this.filter.dates[1])
                    this.filter.dates.reverse();
            }
            return this.filter.dates.join(" ~ ");
        },
        headers() {
            let direction = {text: '', value: ''};
            let date = {text: '', value: ''}
            let number = {text: '', value: ''};
            let lastCol = {text: '', value: ''};
            if(this.reportType === 'По услугам') {
                if (this.serviceType === 'Проживание') {
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Турагент/Туропертор', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'Отель', value: 'hotel'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Заезд-Выезд', value: 'checkInOut'},
                        { text: 'Номер', value: 'room'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.serviceType === 'Автобусы') {
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Турагент/Туропертор', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'Автобус/направление', value: 'route'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Взрослый', value: 'adult_count'},
                        { text: 'Детский', value: 'child_count'},
                        { text: 'Пенсионер', value: 'pensioner_count'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Номер автобуса', value: 'bus_number'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.serviceType === 'Туры') {
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Турагент/Туропертор', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'Направление', value: 'route'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Взрослый', value: 'adult_count'},
                        { text: 'Детский', value: 'child_count'},
                        { text: 'Пенсионер', value: 'pensioner_count'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Номер автобуса', value: 'bus_number'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.serviceType === 'Ж/Д') {
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Турагент/Туропертор', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: '№ поезда/направления', value: 'route'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Взрослый', value: 'adult_count'},
                        { text: 'Детский', value: 'child_count'},
                        { text: 'Пенсионер', value: 'pensioner_count'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Номер вагона', value: 'railway_number'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.serviceType === 'Трансферы') {
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Турагент/Туропертор', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'Маршрут', value: 'route'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Таксист', value: 'tax_number'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                }
            }
            if (this.reportType === 'По каналу продаж') {
                return [
                    { text: 'Дата', value: 'created_at'},
                    { text: 'Турагент/Туропертор', value: 'owner'},
                    { text: 'Номер заявки', value: 'id'},
                    { text: 'Услуга', value: 'service_type'},
                    { text: 'ФИО', value: 'client_name'},
                    { text: 'Сумма', value: 'total_price'},
                    { text: 'Оплачено', value: 'paid'},
                    { text: 'Не оплачено', value: 'duty'},
                    { text: 'Баланс', value: 'balance'},
                ]
            }
            if(this.reportType === 'По контрагенту') {
                if (this.searchType === 'by_bus_number') { // по номеру автобуса
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: '№ автобуса', value: 'bus_number'},
                        { text: 'Турагент', value: 'owner'},
                        { text: 'Направление', value: 'route'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Взрослый', value: 'adult_count'},
                        { text: 'Детский', value: 'child_count'},
                        { text: 'Пенсионер', value: 'pensioner_count'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Место', value: 'seating_places'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.searchType === 'by_train_number') { // по номеру поезда
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: '№ поезда', value: 'train_number'},
                        { text: 'Турагент', value: 'owner'},
                        { text: 'Направление', value: 'route'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Взрослый', value: 'adult_count'},
                        { text: 'Детский', value: 'child_count'},
                        { text: 'Пенсионер', value: 'pensioner_count'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Номер вагона', value: 'room'},
                        { text: 'Место', value: 'room'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.searchType === 'by_hotel') { // по отелю
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Отель', value: 'hotel'},
                        { text: 'Турагент', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Заезд-Выезд', value: 'checkInOut'},
                        { text: 'Номер', value: 'room'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                } else if (this.searchType === 'by_tour') { // по туру
                    return [
                        { text: 'Дата', value: 'created_at'},
                        { text: 'Тур', value: 'tour'},
                        { text: 'Турагент', value: 'owner'},
                        { text: 'Номер заявки', value: 'id'},
                        { text: 'ФИО', value: 'client_name'},
                        { text: 'Взрослый', value: 'adult_count'},
                        { text: 'Детский', value: 'child_count'},
                        { text: 'Пенсионер', value: 'pensioner_count'},
                        { text: 'Дата', value: 'checkInOut'},
                        { text: 'Место', value: 'seating_places'},
                        { text: 'Номер автобуса', value: 'bus_number'},
                        { text: 'Сумма', value: 'total_price'},
                        { text: 'Оплачено', value: 'paid'},
                        { text: 'Не оплачено', value: 'duty'},
                    ]
                }
            }

            return [
                {
                    text: 'Дата',
                    value: 'created_at'
                },
                {
                    text: 'Турагент/Туропертор',
                    value: 'owner'
                },
                {
                    text: 'Номер заявки',
                    value: 'id'
                },
                {
                    text: direction.text,
                    value: direction.value
                },
                {
                    text: 'ФИО',
                    value: 'client_name'
                },
                {
                    text: date.text,
                    value: date.value
                },
                {
                    text: number.text,
                    value: number.value
                },
                {
                    text: 'Сумма',
                    value: 'total_price'
                },
                {
                    text: 'Оплачено',
                    value: 'paid'
                },
                {
                    text: 'Не оплачено',
                    value: 'duty'
                },
                {
                    text: lastCol.text,
                    value: lastCol.value
                }
            ]
        },
    },
    filters: {
        convertService(value){
            let service_types = {
                bus_orders: 'Автобус',
                accommodation_orders: 'Проживание',
                tour_orders: 'Тур',
                railway_orders: 'ЖД',
            }
            return service_types[value]
        }
    },
    methods: {
        async getAgentItems(){
            const dates = this.dateRangeText.split('~').map(k => k.trim())
            if(dates.length != 2) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Выберите время!",
                    color: "error",
                });
                return;
            }
            // /report-bus-objects/       /report-tour-objects/     /report-accommodation-objects/     /report-railway-objects/
            let types = {
                by_bus_number: '/report-bus-objects/',
                by_train_number: '/report-railway-objects/',
                by_hotel: '/report-accommodation-objects/',
                by_tour: '/report-tour-objects/',
            }
            let params = {
                from_date: (new Date(dates[0]).getTime()) / 1000,
                to_date: (new Date(dates[1]).getTime()) / 1000,
                date_filter_type: this.dateType,
                search_type: this.searchType,
                search_word: this.searchText
            }
            try{
                this.agent_items = (await reportService.getAgentFilter(params, types[this.searchType]))
                this.agent_items.forEach(e => {
                    e.name = e.name || e.number
                })
            }
            catch(e) {
                this.agent_items = []
                this.searchType = null
                this.searchText = {}
            }
        },
        secondsToDate(seconds) {
            let temp = new Date(1970, 0, 1); // Epoch
            temp.setSeconds(seconds);
            return temp;
        },
        dateFormating(arr, type) {
            arr.forEach(item => {
                if (item.check_in && item.check_out) {
                    let checkIn = this.$moment(this.secondsToDate(item.check_in)).format('YYYY-MM-DD')
                    let checkOut = this.$moment(this.secondsToDate(item.check_out)).format('YYYY-MM-DD')
                    item.checkInOut = checkIn + ' ~ ' + checkOut
                }

                item.created_at = this.$moment(this.secondsToDate(item.created_at)).format('YYYY-MM-DD')

                if (type === 'Ж/Д') {
                    item.route = item.train_number + '/' + item.route
                }
            })
        },
        async formulate() {
            const dates = this.dateRangeText.split('~').map(k => k.trim())
            if(dates.length != 2) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Выберите время!",
                    color: "error",
                });
                return;
            }
            let params = {}


            if (this.reportType !== 'По контрагенту') {
                params = {
                    from_date: (new Date(dates[0]).getTime()) / 1000,
                    to_date: (new Date(dates[1]).getTime()) / 1000,
                    date_filter_type: this.dateType
                }
            } else {
                params = {
                    from_date: (new Date(dates[0]).getTime()) / 1000,
                    to_date: (new Date(dates[1]).getTime()) / 1000,
                    date_filter_type: this.dateType,
                    search_type: this.searchType,
                    search_word: this.searchText.name
                }
            }


            if (this.reportType === 'По контрагенту') {
                try {
                    this.$loading(true)
                    this.list = (await reportService.agentReport(params)).results
                    this.dateFormating(this.list)
                    this.tableStatus = true
                } catch (e) {
                    console.log(e)
                } finally {
                    this.$loading(false)
                }
            }

            if (this.reportType === 'По каналу продаж') {
                try {
                    this.$loading(true)
                    params.search = this.search
                    this.list = (await reportService.salesReport(params)).results
                    this.dateFormating(this.list)
                    this.tableStatus = true
                } catch (e) {
                    console.log(e)
                } finally {
                    this.$loading(false)
                }
            }

            if (this.reportType === 'По услугам') {
                if (this.serviceType === 'Проживание') {
                    try {
                        this.$loading(true)
                        this.list = (await reportService.accommodationReport(params)).results
                        this.dateFormating(this.list, this.serviceType)
                        this.tableStatus = true
                    } catch (e) {
                        console.log(e)
                    } finally {
                        this.$loading(false)
                    }
                } else if (this.serviceType === 'Автобусы') {
                    try {
                        this.$loading(true)
                        this.list = (await reportService.busReport(params)).results
                        this.dateFormating(this.list, this.serviceType)
                        this.tableStatus = true
                    } catch (e) {
                        console.log(e)
                    } finally {
                        this.$loading(false)
                    }
                } else if (this.serviceType === 'Туры') {
                    try {
                        this.$loading(true)
                        this.list = (await reportService.tourReport(params)).results
                        this.dateFormating(this.list, this.serviceType)
                        this.tableStatus = true
                    } catch (e) {
                        console.log(e)
                    } finally {
                        this.$loading(false)
                    }
                } else if (this.serviceType === 'Ж/Д') {
                    try {
                        this.$loading(true)
                        this.list = (await reportService.trainReport(params)).results
                        this.dateFormating(this.list, this.serviceType)
                        this.tableStatus = true
                    } catch (e) {
                        console.log(e)
                    } finally {
                        this.$loading(false)
                    }
                }
            }

        },
        async download(){
            if(!this.reportType) return 
            const dates = this.dateRangeText.split('~').map(k => k.trim())
            if(dates.length != 2) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Выберите время!",
                    color: "error",
                });
                return;
            }
            let params = {}
            if (this.reportType !== 'По контрагенту') {
                params = {
                    from_date: (new Date(dates[0]).getTime()) / 1000,
                    to_date: (new Date(dates[1]).getTime()) / 1000,
                    date_filter_type: this.dateType
                }
            } else {
                params = {
                    from_date: (new Date(dates[0]).getTime()) / 1000,
                    to_date: (new Date(dates[1]).getTime()) / 1000,
                    date_filter_type: this.dateType,
                    search_type: this.searchType,
                    search_word: this.searchText.name
                }
            }
            if (this.reportType === 'По каналу продаж') params.search = this.search

            let urls = {
                'По контрагенту': '/report-counter-agent/export/', 
                'По каналу продаж': '/report-sales-channel/export/',
                'По услугам': {
                    'Проживание': '/report-accommodation/export/',
                    'Автобусы': '/report-bus/export/',
                    'Туры': '/report-tour/export/',
                    'Ж/Д': '/report-railway/export/',
                },
            }
            let path = typeof urls[this.reportType] === 'object' ? urls[this.reportType][this.serviceType] : urls[this.reportType]
            let res = await axios.get(path, { params, responseType: 'blob', })
            var blob = new Blob([res.data], {
                type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;Content-Disposition: attachment"
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report.xlsx");
            if (typeof link.download === "undefined") {
                link.setAttribute("target", "_blank");
            }
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }, 100);
        }
    }
}
</script>

<style lang="scss" scoped>
.search-bar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    align-items: center;
    margin-bottom: 20px;
}
</style>